<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <!-- <v-col
          cols="12"
          md="5"
        >
          <base-business-info title="KEEP IN TOUCH WITH US">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo pariatur quidem, quis modi iure nihil commodi suscipit, corporis dicta adipisci, voluptatum quia cupiditate.
          </base-business-info>
        </v-col> -->

        <v-col
          cols="12"
        >
          <div>
            <iframe
              frameborder="0"
              style="height:1200px;width:99%;border:none;"
              src="https://forms.zohopublic.in/hi5magicofficial/form/OrderForm/formperma/jR4Q8vVWj_Ny5S6vo3dq_310p2F_UWqg-L8cPtKr0DM"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCalendarForm',
  }
</script>
